"use client";
import * as React from "react";
import { motion } from "framer-motion";
import { Container } from "@mui/material";

const Loading = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        padding: "0 10px",
      }}
    >
      <motion.div
        style={{
          width: "100px",
          height: "100px",
          background: "#06d6a0",
          borderRadius: "30px",
        }}
        animate={{ rotate: 90}} // Rotate full circle (360 degrees)
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 20,
          repeat: Infinity, // Key change: Repeat indefinitely
          duration: 3, // Adjust the duration of a single rotation
        }}
      />
    </Container>
  );
};

export default Loading;
