import {
  Paper,
  Stack,
  Typography,
  Box,
  List,
  ListItemText,
  Button,
} from "@mui/material";
import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import { QuestianResultProps } from "../../common/types";

const Heading = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
  },
}));

const FlexPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#202222",
  width: "40vw",
  [theme.breakpoints.down("lg")]: {
    width: "60vw",
  },
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90vw",
  },
}));

const QuestianResults = (props: QuestianResultProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        pt: "5vh",
      }}
    >
      <FlexPaper>
        <Stack spacing={2} margin={2}>
          <Box sx={{ width: "10vh" }}>
            <Button
              onClick={props.resetLoaded}
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{
                color: "#ffffff",
                borderColor: "#ffffff",
                "&:hover": {
                  color: "#06d6a0",
                  borderColor: "#06d6a0", // Change to your desired focus color
                },
              }}
              size="small"
            >
              Back
            </Button>
          </Box>
          <Heading variant="h5" color="#ffffff">
            {props.providedTranscript
              ? props.providedTranscript
              : props.queryData?.transcript}
          </Heading>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <AutoAwesomeIcon
                sx={{
                  color: "#06d6a0",
                  fontSize: 25,
                }}
              />
              <Typography variant="h6" color="#06d6a0">
                Questions
              </Typography>
            </Box>
            <List>
              {props.queryData?.questions.map((source, index) => (
                <ListItemText
                  primary={"➤ " + source}
                  key={index}
                  primaryTypographyProps={{ fontSize: "14px" }}
                  sx={{ color: "#ffffff", mt: 2 }}
                />
              ))}
            </List>
          </Box>
        </Stack>
      </FlexPaper>
    </Box>
  );
};

export default QuestianResults;
