import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Heading = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  fontWeight: 400,
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5rem",
  },
}));

export default Heading;
