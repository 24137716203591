"use client";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Button,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const drawerWidth = 240;

const CollapsibleNavBar = () => {
  const [open, setOpen] = useState(false); // Start with drawer open

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const handleClick = (url_link: string) => {
    navigate(url_link);
  };

  const drawerContent = (
    <div>
      <Toolbar>
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeftIcon sx={{ color: "#06d6a0" }} />
        </IconButton>
      </Toolbar>
      <Stack spacing={2}>
        <Button
          sx={{ color: "#06d6a0" }}
          fullWidth
          onClick={() => {
            handleClick("/");
          }}
        >
          Home
        </Button>
        <Button
          sx={{ color: "#06d6a0" }}
          fullWidth
          onClick={() => {
            handleClick("/questian");
          }}
        >
          Questian
        </Button>
        <Button
          sx={{ color: "#06d6a0" }}
          fullWidth
          onClick={() => {
            handleClick("/safetie");
          }}
        >
          Safetie
        </Button>
      </Stack>
    </div>
  );

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#131314" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerToggle}
          >
            {open ? (
              <ChevronLeftIcon sx={{ color: "#06d6a0" }} />
            ) : (
              <MenuIcon sx={{ color: "#06d6a0" }} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#202222",
          },
        }}
        anchor="left"
        open={open} // Keep drawer open initially
        onClose={handleDrawerToggle}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default CollapsibleNavBar;
