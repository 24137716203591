import React from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Stack,
  Select,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Heading from "../common/heading";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import InputTypeSwitch from "./inputTypeSwitch";
import UploadButton from "../common/uploadButton";
import { QuestianConfigProps } from "../../common/types";

const QuestianConfig = (props: QuestianConfigProps) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        padding: "0 10px",
      }}
    >
      <Stack spacing={3} alignItems="start">
        <Heading variant="h2">Coloured Questions</Heading>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="caption">Choose your input</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Text</Typography>
                <InputTypeSwitch
                  sx={{ m: 1 }}
                  onChange={props.handleInputTypeChange}
                  checked={props.inputIsAudio}
                />
                <Typography>Audio</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel
                id="tonelabel"
                sx={{
                  color: "#7f8383",
                  "&&.Mui-focused": {
                    color: "#06d6a0",
                  },
                }}
              >
                Tone
              </InputLabel>
              <Select
                fullWidth
                labelId="tonelabel"
                label="Tone"
                value={props.tone}
                onChange={props.handleToneChange}
                sx={{
                  borderRadius: "5px",
                  "& label": {
                    color: "#7f8383", // Default label color
                  },
                  backgroundColor: "#202222",
                  "&.MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#06d6a0",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#06d6a0",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#ffffff",
                  },
                }}
              >
                <MenuItem value="sarcastic">Sarcastic</MenuItem>
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="stoic">Stoic</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            height: "3.5rem",
          }}
        >
          {props.inputIsAudio ? (
            <UploadButton handleUploadFileChange={props.handleFileChange} />
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              label="Enter Transcript"
              onChange={props.handleTranscriptChange}
              value={props.transcriptText}
              sx={{
                borderRadius: "5px",
                "& label": { color: "#7f8383" }, // Default label color
                "& label.Mui-focused": { color: "#06d6a0" }, // Focused label color
                "& .MuiOutlinedInput-root": {
                  // Target the input element
                  "&:hover fieldset": {
                    borderColor: "#06d6a0",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#06d6a0",
                  },
                },
                backgroundColor: "#202222",
                "& .MuiInputBase-input": {
                  color: "#ffffff",
                },
              }}
            />
          )}
          <IconButton size="large" onClick={props.submit}>
            <NearMeRoundedIcon
              sx={{
                transition: "color 0.2s ease-in-out",
                color: "#06d6a0", //TODO add color changes
                fontSize: 25,
              }}
            />
          </IconButton>
        </Box>
      </Stack>
    </Container>
  );
};

export default QuestianConfig;
