import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { ToolCardProps } from "../../common/types";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
// ... rest of your imports

const StyledCapitalTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Birthstone Bounce", cursive',
  fontWeight: 400,
  fontSize: "6rem",
  color: "#06d6a0",
}));

const ToolCard = (props: ToolCardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(props.tool_url);
  };

  return (
    <Card sx={{ backgroundColor: "#202222", height: "100%" }}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <StyledCapitalTypography sx={{ textAlign: "center" }}>
            {props.capital}
          </StyledCapitalTypography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ color: "#ffffff" }}
          >
            {props.heading}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ color: "#ffffff" }}
          >
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ToolCard;
