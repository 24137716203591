import {
  Paper,
  Stack,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BookIcon from "@mui/icons-material/Book";
import { styled } from "@mui/material/styles";
import { ResultProps } from "../../common/types";

const Heading = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
  },
}));

const FlexPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#202222",
  width: "40vw",
  [theme.breakpoints.down("lg")]: {
    width: "60vw",
  },
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90vw",
  },
}));

const Results = (props: ResultProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        pt: "5vh",
      }}
    >
      <FlexPaper>
        <Stack spacing={2} margin={2}>
          <Box sx={{ width: "10vh" }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{
                color: "#ffffff",
                borderColor: "#ffffff",
                "&:hover": {
                  color: "#06d6a0",
                  borderColor: "#06d6a0", // Change to your desired focus color
                },
              }}
              size="small"
              onClick={props.resetLoaded}
            >
              Back
            </Button>
          </Box>
          <Heading variant="h4" color="#ffffff">
            {props.queryText}
          </Heading>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <BookIcon
                sx={{
                  color: "#06d6a0",
                  fontSize: 25,
                }}
              />
              <Typography variant="h6" color="#06d6a0">
                Sources
              </Typography>
            </Box>
            <List>
              {(props.queryData ? props.queryData.Sources : []).map(
                (source, index) => (
                  <ListItemButton key={index}>
                    <ListItemText
                      primary={source}
                      primaryTypographyProps={{ fontSize: "14px" }}
                      sx={{ color: "#ffffff", margin: 0 }}
                    />
                  </ListItemButton>
                )
              )}
            </List>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <AutoAwesomeIcon
                sx={{
                  color: "#06d6a0",
                  fontSize: 25,
                }}
              />
              <Typography variant="h6" color="#06d6a0">
                Answer
              </Typography>
            </Box>
            <Typography padding={2} variant="body2" color="#ffffff">
              {props.queryData ? props.queryData.LLM_response : ""}
            </Typography>
          </Box>
        </Stack>
      </FlexPaper>
    </Box>
  );
};

export default Results;
