import React from "react";
import { Container, TextField, Box, Stack, IconButton } from "@mui/material";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import { SearchBarProps } from "../../common/types";
import Heading from "../common/heading";

const SearchBar = (props: SearchBarProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.submit();
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        padding: "0 10px",
      }}
    >
      <Stack spacing={5} alignItems="center">
        <Heading variant="h2">Practice Safety Always</Heading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Ask away ..."
            onChange={props.queryTextChange}
            onKeyDown={handleKeyDown}
            value={props.queryText}
            sx={{
              borderRadius: "5px",
              "& label": { color: "#7f8383" }, // Default label color
              "& label.Mui-focused": { color: "#06d6a0" }, // Focused label color
              "& .MuiOutlinedInput-root": {
                // Target the input element
                "&:hover fieldset": {
                  borderColor: "#06d6a0",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#06d6a0",
                },
              },
              backgroundColor: "#202222",
              "& .MuiInputBase-input": {
                color: "#ffffff",
              },
            }}
          />
          <IconButton
            size="large"
            disabled={!props.queryText}
            onClick={props.submit}
          >
            <NearMeRoundedIcon
              sx={{
                transition: "color 0.2s ease-in-out",
                color: props.queryText ? "#06d6a0" : "#8d9191",
                fontSize: 25,
              }}
            />
          </IconButton>
        </Box>
      </Stack>
    </Container>
  );
};

export default SearchBar;
