import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { UploadButtonProps } from "../../common/types";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadButton(props: UploadButtonProps) {
  return (
    <Tooltip title="Only accepting audios up to 30 seconds long">
      <Button
        component="label"
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        sx={{
          color: "#ffffff",
          backgroundColor: "#06d6a0",
          height: "100%",
        }}
        fullWidth
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          onChange={props.handleUploadFileChange}
        />
      </Button>
    </Tooltip>
  );
}
