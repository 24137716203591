import React from "react";
import ToolCard from "../components/home/toolcard";
import { Container, Grid } from "@mui/material";

const Home = () => {
  // TODO set up a dedicated file for list item parameters into ToolCards
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        padding: "0 10px",
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={4} md={3} alignItems="center">
          <ToolCard
            capital="Q"
            heading="Questian"
            description="A tool for questioning your transcripts with style"
            tool_url="/questian"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} alignItems="center">
          <ToolCard
            capital="S"
            heading="Safetie"
            description="A tool for helping you understand site safety"
            tool_url="/safetie"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
