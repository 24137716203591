import Loading from "./loading";
import SearchBar from "../components/safety_search/searchbar";
import Results from "../components/safety_search/results";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { querySafetie } from "../api/api"; // Assuming 'api.ts' is in the same directory
import { SafetieQueryState } from "../common/types";

// TODO Put in proper Auth
const phrase = "SpookyPlatypus";

export default function Safetie() {
  const [queryText, setQueryText] = useState("");
  const [queryState, setQueryState] = useState<SafetieQueryState>({
    status: "Not Loaded",
  });

  const handleQueryTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQueryText(event.target.value);
  };

  const handleSubmit = async () => {
    setQueryState({ status: "Loading" });

    try {
      const response = await querySafetie({
        content: queryText,
        secret: phrase,
      });
      setQueryState({ status: "Loaded", data: response });
    } catch (error) {
      setQueryState({ status: "Loaded", error: error as Error });
    }
  };

  const handleLoadedReset = () => {
    setQueryState({ status: "Not Loaded" });
    setQueryText("");
  };

  return (
    <AnimatePresence mode="wait">
      {queryState.status === "Loading" ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Loading />
        </motion.div>
      ) : queryState.status === "Loaded" ? (
        <motion.div
          key="results"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Results
            resetLoaded={handleLoadedReset}
            queryText={queryText}
            queryData={queryState.data}
          />
        </motion.div>
      ) : (
        <motion.div
          key="searchbar"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
        >
          <SearchBar
            queryTextChange={handleQueryTextChange}
            queryText={queryText}
            submit={handleSubmit}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
