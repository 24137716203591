import axios from "axios";
import {
  SafetieQuery,
  QuestianTextQuery,
  QuestianAudioQuery,
  SafetieResponse,
  QuestianResponse,
} from "../common/types";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const queryQuestianText = async (
  query: QuestianTextQuery
): Promise<QuestianResponse> => {
  try {
    const response = await api.post("/generate-questions-from-text", query);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const queryQuestianAudio = async (
  query: QuestianAudioQuery
): Promise<QuestianResponse> => {
  try {
    const formData = new FormData();
    if (query.audio) {
      formData.append("file", query.audio, "audio.mp3");
    }
    const response = await api.post(
      `/generate-questions-from-audio?characteristic=${query.characteristic}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const querySafetie = async (
  query: SafetieQuery
): Promise<SafetieResponse> => {
  try {
    const response = await api.post("/query/", query);
    return response.data;
  } catch (error) {
    throw error;
  }
};
