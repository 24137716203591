import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import QuestianConfig from "../components/question_from_transcripts/questianConfig";
import { QuestianQueryState, QuestianConfigSetting } from "../common/types";
import { queryQuestianAudio, queryQuestianText } from "../api/api";
import { SelectChangeEvent } from "@mui/material";
import QuestianResults from "../components/question_from_transcripts/questianResults";
import Loading from "./loading";

const initialQuestianConfig: QuestianConfigSetting = {
  inputIsAudio: false,
  transcriptText: "",
  transcriptAudio: null,
  tone: "",
};

const Questian = () => {
  const [questianConfig, setQuestianConfig] = useState(initialQuestianConfig);
  const [queryState, setQueryState] = useState<QuestianQueryState>({
    status: "Not Loaded",
  });

  const handleTranscriptChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestianConfig((prevConfig) => ({
      ...prevConfig,
      transcriptText: event.target.value,
    }));
  };

  const handleToneChange = (event: SelectChangeEvent) => {
    setQuestianConfig((prevConfig) => ({
      ...prevConfig,
      tone: event.target.value,
    }));
  };

  const handleInputTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestianConfig((prevConfig) => ({
      ...prevConfig,
      inputIsAudio: event.target.checked,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setQuestianConfig((prevConfig) => ({
        ...prevConfig,
        transcriptAudio: file,
      }));
    }
  };

  const handleSubmit = async () => {
    setQueryState({ status: "Loading" });

    let response;

    try {
      if (!questianConfig.inputIsAudio) {
        response = await queryQuestianText({
          transcript: questianConfig.transcriptText,
          characteristic: questianConfig.tone,
        });
      } else {
        response = await queryQuestianAudio({
          audio: questianConfig.transcriptAudio,
          characteristic: questianConfig.tone,
        });
      }
      setQueryState({ status: "Loaded", data: response });
    } catch (error) {
      setQueryState({ status: "Loaded", error: error as Error });
    }
  };

  const handleLoadedReset = () => {
    setQueryState({ status: "Not Loaded" });
    setQuestianConfig((prevConfig) => ({
      ...prevConfig,
      ...initialQuestianConfig,
    }));
  };

  return (
    <AnimatePresence mode="wait">
      {queryState.status === "Loading" ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Loading />
        </motion.div>
      ) : queryState.status === "Loaded" ? (
        <motion.div
          key="results"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <QuestianResults
            resetLoaded={handleLoadedReset}
            providedTranscript={questianConfig.transcriptText}
            queryData={queryState.data}
          />
        </motion.div>
      ) : (
        <motion.div
          key="searchbar"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
        >
          <QuestianConfig
            tone={questianConfig.tone}
            transcriptText={questianConfig.transcriptText}
            inputIsAudio={questianConfig.inputIsAudio}
            handleInputTypeChange={handleInputTypeChange}
            handleToneChange={handleToneChange}
            handleTranscriptChange={handleTranscriptChange}
            handleFileChange={handleFileChange}
            submit={handleSubmit}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Questian;
